import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Logo from './logo.js'

const HeaderStyle = styled.header`
      margin-bottom: 1.45rem;
`
const HeaderContent = styled.div`
        margin: 0 auto;
        max-width: 960;
        padding: 1.45rem 1.0875rem;
        background-color: green;
`

const Header = ({ siteTitle }) => (
  <>
    {/*} <HeaderStyle>
    <HeaderContent>
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle} (bytt ut med logo)
        </Link>
      </h1>
    </HeaderContent>
  </HeaderStyle>
        {*/}
    <nav className="navbar is-warning" role="navigation" aria-label="hovedmeny">
      <div className='navbar-brand'>

        <Link to="/" >
          <Logo />
          </Link>

      </div>

      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <a href="#first"> Skip link </a> 
          <Link to="/about/" className='navbar-item'>Om oss</Link>
          <Link to="/blog/" className='navbar-item'>Blog</Link>
          <Link to="/employees/" className='navbar-item'>Ansatte</Link>

        </div>
      </div>
    </nav>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
