import { Link } from "gatsby"
import React from "react"

const Footer = () => {
    return (
    <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>Fossekall Teknologi</strong> 
      </p>
    </div>
  </footer>
)
  }
  
  export default Footer
  