
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
const Logo = () => {
    const data = useStaticQuery(graphql`
    query {
    file(relativePath: { eq: "fossekall_logo1.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`
      )
    return (
         <Img
           fixed={data.file.childImageSharp.fixed}
           alt="Logo"
           loading='eager' 
         /> 
    )


}

export default Logo